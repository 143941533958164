.container {
  @apply !m-0 !w-full gap-6;
}

.accordion {
  box-sizing: border-box;
  height: 100%;
  border: 0 !important;
  padding: 24px;
  overflow: auto !important;
}

.accordion :global .MuiAccordionSummary-root {
  background: transparent !important;
  padding: var(--space-3);
  border-bottom: 1px solid var(--color-border-light);
  pointer-events: none;
}

.accordion :global .MuiAccordionSummary-content {
  margin: 0 !important;
}

.sidebar {
  max-height: calc(100vh - var(--header-height) - var(--footer-height) - 96px);
  overflow: auto;
  padding: 0 !important;
  border-radius: 24px;
  border: 0.5px solid var(--m-3-sys-light-outline-variant);
  background: var(--m-3-sys-light-background);
}

.sidebar-header {
  color: var(--m-3-ref-neutral-variant-neutral-variant-20) !important;
  font-family: Roboto !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 24px !important;
  letter-spacing: 0.15px !important;
}

.content {
  background: linear-gradient(180deg, #b2d4fc 0%, #6aaeff 100%);
  background-size: 200% 200%;
  animation: gradient 15s ease infinite;
  border-radius: 24px;
  padding: var(--space-5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: calc(100vh - var(--header-height) - var(--footer-height) - 96px);
  min-height: 450px;
}

.content-header {
  margin-bottom: 16px;
  color: var(--m-3-sys-light-on-surface-variant);
  font-family: Roboto;
  font-size: 45px;
  font-style: normal;
  font-weight: 500;
  line-height: 52px;
}

.content-description {
  margin-bottom: 56px;
  color: var(--m-3-sys-light-on-surface-variant);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.content-items-container {
  padding: 0 !important;
  margin: 0 !important;
  gap: 24px;
}

.content-item-wrapper {
  max-width: 296px;
}

.content-item-container {
  padding: 24px !important;
  border-radius: 24px !important;
  background: var(--m-3-sys-light-background);
}

.content-item-header {
  margin: 16px 0 8px 0;
  color: var(--m-3-ref-neutral-variant-neutral-variant-20);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.content-item-description {
  margin-bottom: 48px;
  color: var(--m-3-ref-neutral-variant-neutral-variant-60);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.button {
  width: 100%;
  height: 40px !important;
  text-transform: capitalize !important;
  border-radius: 8px !important;
  text-align: center;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px !important;
  letter-spacing: 0.1px;
}

.new-safe-button {
  composes: button;
  background: var(--m-3-sys-light-primary) !important;
  color: var(--m-3-sys-light-on-primary) !important;
}

.load-safe-button {
  composes: button;
  border: 1px solid var(--m-3-sys-light-outline) !important;
  color: var(--m-3-sys-light-on-surface-variant) !important;
}

.hints-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  right: 40px;
  bottom: -10px;
  width: 393px;
  padding: 24px;
  border-radius: 4px;
  background: var(--m-3-sys-light-background, #fdfcff);
  box-shadow:
    0px 2px 6px 2px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  gap: 16px;

}

.hints-title {
  @apply mb-2;
  color: var(--m-3-ref-neutral-variant-neutral-variant-20, #2c3137);
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.later-button {
  @apply w-[57px] h-[40px] !mr-4;
  color: var(--m-3-ref-neutral-variant-neutral-variant-20, #2c3137) !important;
  text-align: center;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px !important;
  letter-spacing: 0.1px;
}

.logo {
  height: 24px;
}

.visit-button {
  @apply w-[107px] h-[40px];
  border-radius: 8px !important;
  background: var(--m-3-sys-light-primary, #0060a9) !important;
  color: var(--m-3-sys-light-on-primary, #fff) !important ;
  text-align: center;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px !important;
  letter-spacing: 0.1px;
  width: 151px;
}

.hints-description {
  @apply mb-4;
  color: var(--m-3-ref-neutral-variant-neutral-variant-60, #8d9199);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}

@media (min-width: 1440px) {
  .accordion :global .MuiAccordionDetails-root > div > div:first-child,
  .accordion :global .MuiAccordionSummary-expandIconWrapper {
    display: none;
  }

  .sidebar {
    width: 432px;
  }
}

@media (min-width: 1240px) and (max-width: 1439px) {
  .accordion :global .MuiAccordionDetails-root > div > div:first-child,
  .accordion :global .MuiAccordionSummary-expandIconWrapper {
    display: none;
  }

  .sidebar {
    width: 432px;
  }
}

@media (min-width: 768px) and (max-width: 1239px) {
  .container {
    @apply !flex-col;
  }

  .sidebar {
    height: auto;
  }

  .accordion :global .MuiAccordionSummary-root {
    padding: 0 !important;
    border: 0;
    min-height: initial;
  }

  .content {
    height: auto;
  }

  .content-header {
    font-size: 36px;
    line-height: 44px;
  }

  .content-item-wrapper {
    max-width: initial;
    width: 100%;
  }

  .content-item-description {
    margin-bottom: 58px;
  }
}

@media (max-width: 767px) {
  .container {
    @apply !flex-col box-border;
  }

  .sidebar {
    height: auto;
  }

  .accordion :global .MuiAccordionSummary-root {
    padding: 0 !important;
    border: 0;
    min-height: initial;
  }

  .content {
    height: auto;
  }

  .content-header {
    font-size: 36px;
    line-height: 44px;
  }

  .content-item-wrapper {
    max-width: initial;
    width: 100%;
  }

  .content-item-description {
    margin-bottom: 58px;
  }

  .hints-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 100%;
    transform: translateX(-50%);
    gap: 16px;
  }

  .logo {
    height: 40px;
  }
}

@keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.announcement-link {
  color: #6AAEFF;
  text-decoration: none;
}

.announcement-link:hover {
  text-decoration: underline;
}