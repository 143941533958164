.ownersArray {
  display: flex;
  flex-direction: column;
  gap: var(--space-2);
  font-size: 14px;
}

.label {
  color: var(--m-3-sys-light-on-surface-variant);
  font-family: Roboto !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important;
}

.value {
  color: var(--m-3-sys-light-on-surface-variant);
  font-family: Roboto !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important;
}

.owner-logo {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: var(--m-3-sys-light-primary-container);
}

.owner-name {
  color: var(--m-3-sys-light-on-surface);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.owner-address {
  color: var(--m-3-ref-neutral-neutral-60);
  text-overflow: ellipsis;
  whitespace: nowrap;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.fee-container {
  width: fit-content;
  margin-bottom: 22px;
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  height: 32px;
  padding: 6px 16px 6px 8px;
  gap: 8px;
  border-radius: 8px;
  background: var(--m-3-sys-light-secondary-container);
}

.fee-description {
  color: var(--m-3-ref-neutral-variant-neutral-variant-60);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.ownersArray :global .MuiIconButton-sizeSmall path {
  fill: #c3c6cf;
}
