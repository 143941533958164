.container {
  display: inline-flex;
  align-items: center;
  gap: var(--space-1);
  color: var(--m-3-sys-light-on-surface-variant);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.verticalAlign {
  vertical-align: middle;
}
