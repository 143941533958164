.gridRow {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 24px;
}

.title {
  min-width: 104px;
  marign-right: 24px;
  color: var(--m-3-sys-light-on-surface-variant, #43474e);
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
}

.gridRow > * {
  flex-shrink: 0;
}

.valueWrapper {
  min-width: 50%;
  flex-shrink: 0;
}

.rawData {
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .gridRow {
    grid-template-columns: 1fr;
    gap: 0;
    margin-top: var(--space-1);
  }
}
