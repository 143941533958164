.title {
  padding: 24px !important;
  color: var(--m-3-sys-light-on-surface-variant, #43474e);
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.dialog :global .MuiPaper-root {
  max-width: 542px;
}

.dialog :global .MuiDialogActions-root {
  border-top: 2px solid var(--color-border-light);
  padding: var(--space-3);
}

.dialog :global .MuiDialogActions-root > :last-of-type:not(:first-of-type) {
  order: 2;
}

.dialog :global .MuiDialogActions-root:after {
  content: "";
  order: 1;
  flex: 1;
}
