@import url(./vars.css);
@import url(./onboard.css);
@import url(../../node_modules/@safe-global/safe-react-components/dist/fonts.css);
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f7f4ed;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #e0cbcb;
    border-radius: 100vh;
    border: 3px solid #f6f7ed;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #c0a0b9;
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family:
    DM Sans,
    sans-serif;
  background-color: var(--color-background-paper);
}

main {
  width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  font: inherit;
}

:focus-visible {
  outline: 5px auto Highlight;
  outline: 5px auto -webkit-focus-ring-color;
}

* {
  box-sizing: border-box;
}

:root {
  --header-height: 56px;
  --footer-height: 40px;
  --m-3-ref-neutral-variant-neutral-variant-20: #2c3137;
  --m-3-ref-neutral-variant-neutral-variant-60: #8d9199;
  --m-3-ref-neutral-neutral-60: #909094;
  --m-3-ref-primary-primary-60: #4d94e3;
  --m-3-ref-primary-primary-95: #eaf1ff;
  --m-3-ref-primary-primary-96: #eff4ff;
  --m-3-sys-light-on-primary-fixed-variant: #004881;
  --m-3-sys-light-outline: #73777f;
  --m-3-sys-light-outline-variant: #c3c6cf;
  --m-3-sys-light-background: #fdfcff;
  --m-3-sys-light-on-surface-variant: #43474e;
  --m-3-sys-light-on-surface: #1a1c1e;
  --m-3-sys-light-surface: #faf9fd;
  --m-3-sys-light-primary: #0060a9;
  --m-3-sys-light-primary-container: #d3e4ff;
  --m-3-sys-light-on-primary: #fff;
  --m-3-sys-light-on-primary-container: #001c38;
  --m-3-sys-light-surface-container-highest: #e3e2e6;
  --m-3-sys-light-secondary-container: #d7e3f8;
  --m-3-sys-light-on-secondary-container: #111c2b;
  --m-3-ref-error-error-60: #ff5449;
  --m-3-ref-error-error-95: #ffedea;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.illustration-main-fill {
  fill: var(--color-primary-main);
}

.illustration-light-fill {
  fill: var(--color-border-main);
}

.illustration-background-fill {
  fill: var(--color-logo-background);
}

/* Note: a fallback `stroke` property must be on the svg to work */
.illustration-main-stroke {
  stroke: var(--color-primary-main);
}

.illustration-light-stroke {
  stroke: var(--color-border-main);
}

.illustration-background-stroke {
  stroke: var(--color-logo-background);
}

@media (max-width: 767px) {
  .sticky {
    position: sticky;
    right: 0;
    background: var(--color-background-paper);
  }
}

body.beamerAnnouncementBarTopActive {
  padding-top: 0 !important;
}

#beamerLastPostTitle {
  left: 330px !important;
}

div.s-enter-active button > div {
  max-width: none !important;
}

#ton-connect-button button {
  background: var(--m-3-sys-light-primary) !important;
  gap: 8px;
  padding: 0 24px 0 16px;
}

#ton-connect-button button > div {
  color: var(--m-3-sys-light-on-primary);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
}
