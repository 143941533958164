.buttonExpand {
  margin-top: 8px;
  padding: 0;
}

.container :global .MuiSvgIcon-fontSizeMedium path {
  fill: #c3c6cf;
}

.container :global .MuiBox-root {
  color: var(--m-3-ref-neutral-neutral-60, #909094);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
