.container {
  @apply flex justify-start items-center gap-4;
}

.container :global .MuiIconButton-sizeSmall path {
  fill: #c3c6cf;
}

.name {
  color: var(--m-3-sys-light-on-surface, #1a1c1e);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.address {
  overflow: hidden;
  color: var(--m-3-ref-neutral-neutral-60, #909094);
  text-overflow: ellipsis;
  whitespace: nowrap;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
