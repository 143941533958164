.header {
  margin-left: 56px;
  color: var(--m-3-ref-neutral-variant-neutral-variant-70, #a8abb4);
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
}

.container :global .MuiBox-root {
  display: none;
}

.container :global .MuiIconButton-sizeSmall path {
  fill: #c3c6cf;
}

.address {
  color: var(--m-3-ref-neutral-neutral-60, #909094);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
