/* This file is generated from the MUI theme colors. Do not edit directly. */

:root {
  --color-text-primary: #121312;
  --color-text-secondary: #a1a3a7;
  --color-text-disabled: #dddee0;
  --color-primary-dark: #3c3c3c;
  --color-primary-main: #121312;
  --color-primary-light: #636669;
  --color-secondary-dark: #0fda6d;
  --color-secondary-main: #a2c9ff;
  --color-secondary-light: #eaf1ff;
  --color-secondary-background: #fdfcff;
  --color-border-main: #a1a3a7;
  --color-border-light: #eaf1ff;
  --color-border-background: #f4f4f4;
  --color-error-dark: #ac2c3b;
  --color-error-main: #ff5f72;
  --color-error-light: #ffb4bd;
  --color-error-background: #ffe6ea;
  --color-success-dark: #028d4c;
  --color-success-main: #00b460;
  --color-success-light: #72f5b8;
  --color-success-background: #f2fff9;
  --color-info-dark: #52bfdc;
  --color-info-main: #5fddff;
  --color-info-light: #b7f0ff;
  --color-info-background: #effcff;
  --color-warning-dark: #cd674e;
  --color-warning-main: #ff8061;
  --color-warning-light: #ffb7a6;
  --color-warning-background: #fff0ed;
  --color-background-default: #f4f4f4;
  --color-background-main: #f4f4f4;
  --color-background-paper: #ffffff;
  --color-background-light: #e7f2ff;
  --color-backdrop-main: #636669;
  --color-logo-main: #121312;
  --color-logo-background: #eeeff0;
  --color-static-main: #121312;
  --space-1: 8px;
  --space-2: 16px;
  --space-3: 24px;
  --space-4: 32px;
  --space-5: 40px;
  --space-6: 48px;
  --space-7: 56px;
  --space-8: 64px;
  --space-9: 72px;
  --space-10: 80px;
  --space-11: 88px;
  --space-12: 96px;
}

[data-theme="dark"] {
  --color-text-primary: #121312;
  --color-text-secondary: #a1a3a7;
  --color-text-disabled: #dddee0;
  --color-primary-dark: #3c3c3c;
  --color-primary-main: #121312;
  --color-primary-light: #636669;
  --color-secondary-dark: #0fda6d;
  --color-secondary-main: #a2c9ff;
  --color-secondary-light: #eaf1ff;
  --color-secondary-background: #fdfcff;
  --color-border-main: #a1a3a7;
  --color-border-light: #eaf1ff;
  --color-border-background: #f4f4f4;
  --color-error-dark: #ac2c3b;
  --color-error-main: #ff5f72;
  --color-error-light: #ffb4bd;
  --color-error-background: #ffe6ea;
  --color-success-dark: #028d4c;
  --color-success-main: #00b460;
  --color-success-light: #72f5b8;
  --color-success-background: #f2fff9;
  --color-info-dark: #52bfdc;
  --color-info-main: #5fddff;
  --color-info-light: #b7f0ff;
  --color-info-background: #effcff;
  --color-warning-dark: #cd674e;
  --color-warning-main: #ff8061;
  --color-warning-light: #ffb7a6;
  --color-warning-background: #fff0ed;
  --color-background-default: #f4f4f4;
  --color-background-main: #f4f4f4;
  --color-background-paper: #ffffff;
  --color-background-light: #e7f2ff;
  --color-backdrop-main: #636669;
  --color-logo-main: #121312;
  --color-logo-background: #eeeff0;
  --color-static-main: #121312;
}

/* The same as above for the brief moment before JS loads */
@media (prefers-color-scheme: light) {
  :root:not([data-theme="light"]) {
    --color-text-primary: #121312;
    --color-text-secondary: #a1a3a7;
    --color-text-disabled: #dddee0;
    --color-primary-dark: #3c3c3c;
    --color-primary-main: #121312;
    --color-primary-light: #636669;
    --color-secondary-dark: #0fda6d;
    --color-secondary-main: #a2c9ff;
    --color-secondary-light: #eaf1ff;
    --color-secondary-background: #fdfcff;
    --color-border-main: #a1a3a7;
    --color-border-light: #eaf1ff;
    --color-border-background: #f4f4f4;
    --color-error-dark: #ac2c3b;
    --color-error-main: #ff5f72;
    --color-error-light: #ffb4bd;
    --color-error-background: #ffe6ea;
    --color-success-dark: #028d4c;
    --color-success-main: #00b460;
    --color-success-light: #72f5b8;
    --color-success-background: #f2fff9;
    --color-info-dark: #52bfdc;
    --color-info-main: #5fddff;
    --color-info-light: #b7f0ff;
    --color-info-background: #effcff;
    --color-warning-dark: #cd674e;
    --color-warning-main: #ff8061;
    --color-warning-light: #ffb7a6;
    --color-warning-background: #fff0ed;
    --color-background-default: #f4f4f4;
    --color-background-main: #f4f4f4;
    --color-background-paper: #ffffff;
    --color-background-light: #e7f2ff;
    --color-backdrop-main: #636669;
    --color-logo-main: #121312;
    --color-logo-background: #eeeff0;
    --color-static-main: #121312;
  }
}
