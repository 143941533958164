.container {
  position: relative;
}

.threshold {
  position: absolute;
  top: -6px;
  right: -6px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 26px;
  height: 16px;
  border-radius: 25%/50%;
  padding: 0 4px;
  color: var(--m-3-sys-light-on-primary) !important;
  text-align: center;
  /* M3/label/small */
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 145.455% */
  letter-spacing: 0.5px;
  background-color: var(--m-3-sys-light-primary);
}
