.icon {
  @apply flex justify-center items-center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--m-3-sys-light-primary-container);
}

.connector {
  margin-left: 20px !important;
  padding: 0;
}

.connector :global .MuiStepConnector-line {
  border-color: var(--color-border-light);
}

.label {
  padding: 0;
  gap: var(--space-2);
}

.description {
  color: var(--m-3-sys-light-on-surface-variant);
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.instruction {
  color: var(--m-3-ref-neutral-variant-neutral-variant-60);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.back-button {
  text-align: center;
  height: 40px !important;
  text-transform: none !important;
  border-radius: 8px !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px !important;
  letter-spacing: 0.1px;
  color: var(--m-3-sys-light-on-primary-container) !important;
  border: none !important;
  padding: 10px 24px 10px 16px !important;
}

.step-header {
  color: var(--m-3-sys-light-on-surface-variant);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.step-description {
  color: var(--m-3-ref-neutral-neutral-60);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.owner-address {
  color: var(--m-3-ref-neutral-neutral-60);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
