.input-container {
  @apply flex justify-center items-start flex-col gap-1;
  padding: 48px 64px 45px 64px;
}

.description {
  margin-left: 16px;
  color: var(--m-3-sys-light-on-surface-variant);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.anchor {
  color: var(--m-3-sys-light-primary);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-decoration-line: underline;
  cursor: pointer;
}