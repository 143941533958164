.card {
  border: 1px solid var(--color-border-light);
  width: 100%;
  max-width: 359px;
  padding: var(--space-3);
  border-radius: 24px !important;
  border: 1px solid var(--m-3-sys-light-outline-variant);
  box-shadow: none !important;
}

.header {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  gap: var(--space-2);
  margin-bottom: var(--space-2);
  color: var(--m-3-sys-light-on-surface-variant);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.row {
  @apply w-full flex justify-between items-center;
  color: var(--m-3-ref-neutral-variant-neutral-variant-60, #8d9199);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.label {
  color: var(--m-3-sys-light-on-surface-variant);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
}

.value {
  color: var(--m-3-ref-neutral-neutral-60);
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.25px;
  text-align: center;
}

.hints-container {
  @apply p-6 mt-4;
  max-width: 359px;
  border-radius: 24px !important;
  border: 2px solid var(--m-3-ref-primary-primary-60) !important;
  background: var(--m-3-ref-primary-primary-96) !important;
}

.hints-header {
  color: var(--m-3-ref-primary-primary-60);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.hints-description {
  @apply mb-4;
  color: var(--m-3-ref-primary-primary-60);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.error-hints-container {
  composes: hints-container;
  border: 2px solid var(--m-3-ref-error-error-60) !important;
  background: var(--m-3-ref-error-error-95) !important;
}

.error-hints-header {
  composes: hints-header;
  color: var(--m-3-ref-error-error-60) !important;
}

.error-hints-description {
  composes: hints-description;
  color: var(--m-3-ref-error-error-60) !important;
}

@media (min-width: 1440px) {
  .hints-container {
    margin: 0;
  }
}

@media (min-width: 1240px) and (max-width: 1439px) {
  .hints-container {
    margin: 0;
  }
}

@media (min-width: 768px) and (max-width: 1239px) {
  .card {
    max-width: initial;
  }

  .hints-container {
    max-width: initial;
  }
}

@media (max-width: 767px) {
  .card {
    max-width: initial;
  }

  .hints-container {
    max-width: initial;
  }
}
