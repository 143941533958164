.container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  width: 100%;
  overflow-x: auto;
  border-top: 1px solid var(--color-border-light);
}

@media (max-width: 767px) {
  .container {
    grid-template-columns: 1fr;
  }
}

.details {
  display: flex;
  flex-direction: column;
  position: relative;
}

.shareLink {
  position: absolute;
  right: 16px;
  top: 16px;
}

.details.noSigners {
  grid-column: 1 / 3;
}

.loading,
.error,
.txSummary,
.advancedDetails,
.txModule {
  padding: 20px 24px;
}

.txData {
  padding: 24px;
  border-bottom: 1px solid var(--color-border-light);
}

.txSummary,
.advancedDetails {
  height: 100%;
}

.txSigners {
  display: flex;
  flex-direction: column;
  padding: var(--space-3);
  border-left: 1px solid var(--color-border-light);
}

.delegateCall .alert {
  width: fit-content;
  padding: 0 var(--space-1);
}

.multiSend {
  border-top: 1px solid var(--color-border-light);
}

@media (max-width: 767px) {
  .txSigners {
    border-left: 0;
    border-top: 1px solid var(--color-border-light);
  }
}
