.prefix {
  color: var(--m-3-ref-neutral-variant-neutral-variant-60);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.address {
  color: var(--m-3-sys-light-on-surface-variant);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}
