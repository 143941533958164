.container {
  @apply flex justify-between items-center;
  height: var(--footer-height);
  display: flex;
  border-radius: 0 !important;
  background: var(--m-3-sys-light-surface) !important;
  border-top: 0.5px solid var(--m-3-sys-light-outline-variant);
  box-shadow: initial !important;
  padding: 0 24px;
}

.header {
  color: var(--m-3-ref-neutral-neutral-60);
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;
}

.link {
  height: 20px;
  padding: 2px 4px;
  border-radius: 2px;
  border: 0.5px solid #C3C6CF;
}

.v3Logo {
  height: 16px;
}

.v3Text {
  width: 22px;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  color: #0045FF;
}

.v3Arrow {
  width: 16px !important;
  height: 16px !important;
  color: rgba(18, 18, 18, 0.87) !important;
}

.v1Text {
  width: 94px;
  font-size: 11px;
  font-weight: 400;
  color: #2F3033;
}

.v1Arrow {
  width: 16px !important;
  height: 16px !important;
  color: #909094 !important;
}
