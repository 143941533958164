.container {
  padding: 16px 16px 24px 16px;
}

.info {
  padding: 0 var(--space-1);
}

.safe {
  display: flex;
  gap: 16px;
  text-align: left;
  align-items: center;
}

.iconButtons {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 24px;
}

.iconButton {
  border-radius: 4px !important;
  padding: 6px !important;
  background-color: var(--color-background-main) !important;
  width: 24px !important;
  height: 24px !important;
}

.iconButton:hover {
  background-color: var(--color-secondary-background) !important;
}

.info-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
}

.safe-name {
  color: var(--m-3-sys-light-on-surface);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.safe-address {
  color: var(--m-3-ref-neutral-variant-neutral-variant-60);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.balance {
  color: var(--m-3-sys-light-on-secondary-container);
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
}
