/* precendence conflict with MUI */
.icon.icon {
  height: 14.525px !important;
  width: 14.525px !important;
}

.dot.dot {
  height: 10px;
  width: 10px;
}

.container {
  @apply flex flex-col;
  padding: 0;
}

.container::before {
  content: "";
  position: absolute;
  border-left: 2px solid var(--color-border-light);
  left: 11px;
  top: 20px;
  height: calc(100% - 40px);
}

.container :global .MuiListItem-root:first-of-type {
  padding-top: 0;
}

.container :global .MuiListItem-root {
  padding-left: 0;
  padding-right: 0;
}

.container :global .MuiPaper-root > .MuiAccordionSummary-root {
  background: transparent !important;
}

.container :global .MuiPaper-root:hover > .MuiAccordionSummary-root {
  background: transparent !important;
}

.container :global .MuiListItemIcon-root {
  color: var(--color-primary-main);
  justify-content: center;
  min-width: 32px;
  padding: var(--space-1) 0;
  /* FIXME: dark theme (controlled in MUI) */
  background: white;
  /* background-color: var(--color-background-paper);*/
}

.confirmationsTotal {
  color: var(--color-border-main);
  display: inline;
  font-weight: normal;
}

.listFooter {
  margin-left: var(--space-4);
}

.list-item {
  @apply !min-h-[24px] !bg-white !justify-center !py-2 !box-content;
}
