.button {
  text-align: center;
  height: 40px !important;
  text-transform: capitalize !important;
  border-radius: 8px !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px !important;
  letter-spacing: 0.1px;
}

.cancel-button {
  composes: button;
  color: var(--m-3-sys-light-on-primary-container) !important;
  padding: 10px 24px 10px 16px !important;
}

.save-button {
  composes: button;
  padding: 10px 49px !important;
}

.label {
  color: var(--m-3-sys-light-on-surface-variant, #43474e);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
}

.address {
  color: var(--m-3-sys-light-on-surface-variant, #43474e);
  text-align: right;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
