.txCreation,
.txSummary {
  padding: 24px;
  display: flex;
  flex-direction: column;
  padding-right: 60px; /* to not overlap with the share link */
  /* gnosis-safe is 2px but overriden later */
  border-top: 1px solid var(--color-border-light);
}

.name {
  color: var(--m-3-sys-light-on-surface, #1a1c1e);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.address {
  color: var(--m-3-ref-neutral-neutral-60, #909094);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.description {
  color: var(--m-3-ref-neutral-neutral-60, #909094);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.txCreation :global .MuiIconButton-sizeSmall path {
  fill: #c3c6cf;
}

.txSummary :global .MuiBox-root {
  color: var(--m-3-ref-neutral-neutral-60, #909094);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.txSummary :global .MuiSvgIcon-fontSizeMedium path {
  fill: #c3c6cf;
}
