.container {
  @apply pt-2;
}

.header {
  margin-bottom: 24px;
  color: var(--m-3-sys-light-on-surface-variant);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.row {
  width: 100%;
  padding: var(--space-5) var(--space-8);
}

@media (min-width: 768px) and (max-width: 1239px) {
  .container {
    @apply !p-0;
  }
}

@media (max-width: 767px) {
  .container {
    @apply !p-0;
  }
}
