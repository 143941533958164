.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}

.container:last-of-type {
  margin-bottom: 0;
}

.container :global .MuiBox-root {
  display: none;
}

.prefix {
  margin-left: 56px;
  color: var(--m-3-ref-neutral-variant-neutral-variant-70, #a8abb4);
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
}
