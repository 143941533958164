.container {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 6px;
}

.container:first-of-type {
  margin-top: 0;
}

.date {
  @apply mb-2;
  color: var(--m-3-ref-neutral-variant-neutral-variant-60, #8d9199);
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
}
