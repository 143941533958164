.select {
  margin-right: var(--space-2);
}

.select :global .MuiOutlinedInput-notchedOutline {
  border-color: var(--color-border-light);
  border-width: 2px;
}

.select :global .MuiSelect-select {
  padding: 12px 14px;
}

.header {
  @apply flex justify-start items-center gap-2 mb-1;
  color: var(--m-3-sys-light-on-surface-variant);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.description {
  @apply mb-4;
  color: var(--m-3-ref-neutral-variant-neutral-variant-60);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.threshold-status {
  color: var(--m-3-sys-light-on-surface);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}
