.container {
  position: relative;
}

.container button[type="submit"] {
  position: absolute;
  bottom: var(--space-3);
  right: var(--space-3);
  z-index: 1;
}

.title {
  color: var(--m-3-sys-light-on-surface-variant, #43474e);
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.stepIndicator {
  padding-right: var(--space-1);
}

.button {
  color: var(--m-3-sys-light-on-primary-container, #001c38) !important;
  text-align: center;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px !important;
  letter-spacing: 0.1px;
}
