.container {
  margin-top: var(--space-1);
  margin-bottom: var(--space-1);
  padding-right: 32px;
}

.container :global .MuiListItemSecondaryAction-root {
  right: 0px;
}

.safe {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.safe:hover {
  background-color: var(--color-secondary-background);
}

.open {
  padding-left: 16px;
  border: 1px solid var(--color-secondary-light);
  border-left: 6px solid var(--color-secondary-light);
  background-color: var(--color-secondary-background) !important;
}

.withPendingButtons :global .MuiListItemSecondaryAction-root {
  right: 74px;
}

.address {
  color: var(--m-3-sys-light-on-surface-variant, #43474e);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}

@media (max-width: 400px) {
  .withPendingButtons :global .MuiListItemSecondaryAction-root {
    right: 48px;
  }
}
