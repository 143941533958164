.container {
  padding: 5vh 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 1;
}

.description {
  color: var(--m-3-ref-neutral-variant-neutral-variant-60) !important;
  text-align: center;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 20px !important;
  letter-spacing: 0.25px;
}
