.container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1201;
  height: var(--header-height);
  display: flex;
  border-radius: 0 !important;
  background: var(--m-3-sys-light-surface) !important;
  border-bottom: 0.5px solid var(--m-3-sys-light-outline-variant);
  box-shadow: initial !important;
}

.logo,
.network-status,
.connect-button,
.menu {
  padding: 0 var(--space-2);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid var(--m-3-sys-light-outline-variant);
}

.menu {
  cursor: pointer;
}

.connect-button {
  border: 0;
}

.menuButton,
.logo,
.menu {
  flex: 1;
  border: none;
}

.logo svg {
  width: auto;
  display: block;
  color: var(--color-logo-main);
}

.menuButton {
  display: none;
}

.switch {
  @apply flex justify-center items-center px-4 py-[6px] border-solid border-[1px] rounded-[8px] text-sm font-medium leading-[20px] tracking-[0.1px];
  font-family: Roboto;
}

@media (min-width: 1440px) {
}

@media (min-width: 1240px) and (max-width: 1439px) {
}

@media (min-width: 768px) and (max-width: 1239px) {
  .tonkey-text {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .tonkey-text {
    display: none !important;
  }
}
