.name :global .MuiFormHelperText-root {
  position: absolute;
  bottom: -20px;
}

@media (min-width: 768px) and (max-width: 1239px) {
  .name :global .MuiFormHelperText-root {
    position: relative;
    bottom: 0;
  }

  .helper {
    margin-bottom: var(--space-5);
  }
}
