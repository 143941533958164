.card {
  border-radius: 24px !important;
  border: 1px solid var(--m-3-sys-light-outline-variant);
  box-shadow: none !important;
}

.header {
  padding: 24px !important;
}

.header :global .MuiCardHeader-title {
  font-weight: 700;
}

.header :global .MuiCardHeader-subheader {
  color: var(--color-text-primary);
}

.step {
  background: var(--m-3-sys-light-primary) !important;
  height: 24px !important;
  width: 24px !important;
}

.content {
  padding: 0 !important;
}

.actions {
  padding: var(--space-3) 52px;
}

.progress :global .MuiLinearProgress-root::before {
  background: var(--m-3-sys-light-surface-container-highest);
}

@media (max-width: 767px) {
  .header {
    padding: var(--space-2);
    flex-direction: column;
    align-items: flex-start !important;
    gap: var(--space-1);
  }
}
