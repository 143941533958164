.list {
  display: grid;
  gap: 4px;
  padding-top: 24px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.icon {
  min-width: 0 !important;
  margin-right: 12px;
}

.listItemButton {
  border-radius: 6px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.list :global .MuiListItemButton-root {
  color: var(--color-text-primary);
  padding: 16px 24px 16px 16px;
}

[data-theme="dark"] .list :global .Mui-selected {
  border-radius: 8px;
}

.list :global .MuiListItemButton-root:hover {
  border-radius: 8px;
}

.list :global .Mui-selected {
  border-radius: 8px;
  background: var(--m-3-ref-primary-primary-95) !important;
}

.list :global .Mui-selected path {
  fill: var(--m-3-sys-light-on-primary-fixed-variant) !important;
}

.list :global .Mui-selected span {
  color: var(--m-3-sys-light-on-primary-fixed-variant);
}

.listItemButton :global .beamer_icon.active {
  top: auto;
  left: 28px;
  bottom: 10px;
  width: 6px;
  height: 6px;
  color: transparent;
}
