.main {
  display: flex;
  flex-direction: column;
  margin-top: var(--header-height);
  padding: 80px 24px 48px 359px;
  background-color: var(--m-3-sys-light-surface);
  transition: padding 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.without-sidebar {
  height: auto;
  min-height: calc(100vh - var(--header-height) - var(--footer-height));
  padding: 48px 24px 0 24px;
}

.without-footer {
  min-height: calc(100vh - var(--header-height));
}

.content {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.content main {
  padding: var(--space-3);
}

.without-footer main {
  padding-left: 0;
}

@media (min-width: 768px) and (max-width: 1239px) {
  .without-sidebar {
    padding: 24px;
  }

  .content main {
    padding-top: 0;
  }

  .without-footer main {
    padding-top: 24px;
  }

  .smDrawerHidden {
    display: none;
  }
}

@media (max-width: 767px) {
  .without-sidebar {
    padding: 32px 16px 11px 16px;
  }

  .content main {
    padding: 16px;
  }
}
