.indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 70px;
  font-size: 12px;
}

.inlineIndicator {
  background: initial !important;
  padding: 6px 16px;
  border-radius: 8px;
  border: 1px solid var(--m-3-sys-light-outline);
  color: var(--m-3-sys-light-on-surface);
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
}
