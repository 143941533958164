.description {
  color: var(--m-3-sys-light-on-surface-variant, #43474e);
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
}

.toolbar-button {
  border-radius: 8px !important;
  border: 1px solid var(--m-3-sys-light-outline, #73777f) !important;
  color: var(--m-3-sys-light-on-surface-variant, #43474e) !important;
  text-align: center;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px !important;
  letter-spacing: 0.1px;
}

.select-all-button {
  composes: toolbar-button;
  padding: 6px 16px !important;
  border-radius: 8px !important;
  border: 1px solid var(--m-3-sys-light-outline, #73777f) !important;
  color: var(--m-3-sys-light-on-surface-variant, #43474e) !important;
  text-align: center;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px !important;
  letter-spacing: 0.1px;
}

.hidden-button {
  composes: toolbar-button;
  padding: 6px 16px 6px 8px !important;
}
