.container {
  overflow-y: auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-3) var(--space-3) var(--space-2);
  border-bottom: 1px solid var(--color-border-light);
}

.chainDivider {
  width: unset;
  border-radius: 8px;
  text-transform: none;
  height: 27px;
  margin: var(--space-2);
}

.ownedLabelWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ownedLabel {
  cursor: pointer;
  color: var(--color-primary-light);
  margin-top: var(--space-1);
  margin-bottom: var(--space-1);
}

.list {
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0 0;
}

.new-safe-button {
  height: 40px !important;
  text-transform: capitalize !important;
  border-radius: 8px !important;
  text-align: center;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px !important;
  letter-spacing: 0.1px;

  background: var(--m-3-sys-light-primary) !important;
  color: var(--m-3-sys-light-on-primary) !important;
}

.no-safe-instruction {
  margin-bottom: 48px;
  color: var(--m-3-ref-neutral-variant-neutral-variant-60);
  text-align: center;

  /* M3/body/medium */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}

.not-connect-image {
  position: relative;
  width: 200px;
  height: 200px;
}

.not-connect-container {
  @apply flex flex-col items-center justify-center;

  margin-top: 30%;
}

.not-connect-instruction {
  color: var(--m-3-ref-neutral-variant-neutral-variant-60);
  text-align: center;

  /* M3/body/medium */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}

@media (max-width: 1240px) {
  .not-connect-container {
    margin-top: 32px;
  }

  .not-connect-image {
    width: 120px;
    height: 120px;
  }
  .not-connect-instruction {
    margin-top: 16px;
  }
}

@media (max-width: 767px) {
  .list {
    overflow-x: auto;
  }
}

@media (max-width: 1240px) {
  .header {
    display: none;
  }

  .title {
    display: none !important;
    padding: 0;
    border: none;
  }
}
